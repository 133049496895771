<template>
  <div class="container">
    <div class="header">
      我的简历
    </div>
    <div class="edit" v-if="is_existence == 1" @click="goCreate(1)">编辑</div>
    <div v-if="is_existence == 1">
      <!-- 基本信息 -->
      <div class="info">
        <div>
          <div class="yuan">
            <div class="uname">{{myResume.realname}}</div>
            <!-- <div class="zhi">员工</div> -->
            
               <img class="seximg" v-if="myResume.sex == '男'" src="../../assets/sex1.png" alt="" />
                <img class="seximg" v-else src="../../assets/sex2.png" alt="" />
            
             
             
           
          </div>
          <!-- <div class="gs1">公司：{{myResume.company_name == "" ? "暂无" : myResume.company_name}}</div> -->
          <div class="flex1">
              <div >{{myResume.job_status | jobStatus}}</div>
              <div style="margin-left:0.2rem;">手机:{{myResume.phone}}</div>
          </div>
        </div>
        <div v-if="myResume.headimg_url == '' " style=" width: 1.25rem;height:1.25rem;">
          <img class="userimg" v-if="myResume.sex == '女' " src="../../assets/nv.jpg" alt="" />
          <img  class="userimg" v-else src="../../assets/user_1.jpg" alt="">
        </div>
        <div v-else>
          <img class="userimg"  :src="myResume.headimg_url" alt="" />
        </div>
      </div>
      <div class="info1">
      
        <div style="display:flex;margin-top:0.3rem;">
          <div class="item" >
            <img class="imgstyle" src="../../assets/experience.png" alt="">
            <div class="text" v-if="myResume.years_working > 0">{{myResume.years_working}}年</div>
            <div class="text" v-else>应届</div>
          </div>
          <div  class="item">
            <img class="img1" src="../../assets/education.png" alt="">
            <div class="text">{{myResume.education_background}}</div>
          </div>
          <div  class="item">
            <img class="img2" src="../../assets/age.png" alt="">
            <div class="text" v-if="myResume.age > 18 && myResume.age <65">{{myResume.age}}岁</div>
            <div class="text" v-else>保密</div>
          </div>
        </div>
      </div>

      <!-- 户籍所在地 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">户籍所在地</div>
        </div>
        <div class="huji">{{myResume.household_register == "" ? "暂无" : myResume.household_register}}</div>
      </div>

      <!-- 联系邮箱 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">联系邮箱</div>
        </div>
        <div class="huji">{{myResume.email}}</div>
      </div>

      <!-- 意向行业 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">意向行业</div>
        </div>
        <div class="huji">{{myResume.industry}}</div>
      </div>


      <!-- 求职岗位 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">求职岗位</div>
        </div>
        <div class="postAddress">
          <div class="add">{{myResume.jop}}</div>
          <div class="money">{{myResume.salary}}</div>
        </div>
        <div class="profession">期待行业：{{myResume.industry}}</div>
      </div>

        <!-- 教学经历 -->
      <div  class="post1">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">教育院校及专业</div>
        </div>
        <div class="list" >
          <div class="items" v-for="(item,index) in myResume.educational_experience" :key="index">
            <div class="gs">
              <div class="name">{{item.school}}</div>
              <div class="time">{{item.start}} - {{item.end}}</div>
            </div>
            <div class="part">{{item.major}}</div> 
          </div>
        </div>
         
      </div>

       <!-- 政治面貌 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">政治面貌</div>
        </div>
        <div class="huji">{{myResume.politics}}</div>
      </div>

      <!-- 工作年限 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">工作年限</div>
        </div>
        <!-- <div class="huji">{{myResume.years_working}} 年</div> -->

        <div class="huji" v-if="myResume.years_working > 0">{{myResume.years_working}}年</div>
            <div class="huji" v-else>应届</div>
      </div>

      <!-- 求职区域 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">求职区域</div>
        </div>
        <div class="huji">{{myResume.address == "" ? "暂无" : myResume.address}}</div>
      </div>

      <!-- 工作经历 -->
      <div class="post1">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">过往履历</div>
        </div>
        <div class="list" v-if="myResume.work_resume.length>0">
          <div class="item4"  v-for="(item,index) in myResume.work_resume" :key="index">
            
              <div class="i1">{{item.company_name}}</div>
              <div class="i2">{{item.position}}</div> 
              <!-- <textarea v-model="item.working_experience"></textarea> -->
              <div class="i3">{{item.entry}}-{{item.quit}}</div>
          </div>
        </div>
        <div v-else class="nomsg1">
          <img src="../../assets/notel.png" alt="">
          <div>暂无工作经历</div>
        </div>
      </div>

      <!-- 职业资格证书/荣誉奖项 -->
      <div class="post">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">职业资格证书/荣誉奖项</div>
        </div>
        <div class="huji">{{myResume.honor == "" ? "暂无" : myResume.honor}}</div>
      </div>

    

      <!-- 工作技能云 -->
      <div class="post1">
        <div class="postTitle" style="margin-bottom:0.3rem;">
          <div class="dot"></div>
          <div class="txt">工作技能云</div>
        </div>
        <div class="list2" v-if="myResume.skillList.work.length>0">
          <div class="item2"  v-for="(item,index) in myResume.skillList.work" :key="index">{{item.skill_name}}</div>
        </div>
        <div v-else class="nomsg1">
          <img src="../../assets/notel.png" alt="">
          <div>暂无技能云</div>
        </div>
      </div>

       <!-- 生活技能云 -->
      <div class="post1">
        <div class="postTitle" style="margin-bottom:0.3rem;">
          <div class="dot"></div>
          <div class="txt">生活技能云</div>
        </div>
        <div class="list2" v-if="myResume.skillList.lift.length>0">
          <div class="item2"  v-for="(item,index) in myResume.skillList.lift" :key="index">{{item.skill_name}}</div>
        </div>
        <div v-else class="nomsg1">
          <img src="../../assets/notel.png" alt="">
          <div>暂无技能云</div>
        </div>
      </div>

      <!-- 生活技能云 -->
      <div class="post1">
        <div class="postTitle" style="margin-bottom:0.3rem;">
          <div class="dot"></div>
          <div class="txt">其他技能云</div>
        </div>
        <div class="list2" v-if="myResume.skillList.other.length>0">
          <div class="item2"  v-for="(item,index) in myResume.skillList.other" :key="index">{{item.skill_name}}</div>
        </div>
        <div v-else class="nomsg1">
          <img src="../../assets/notel.png" alt="">
          <div>暂无技能云</div>
        </div>
      </div>


      <!-- 下架 -->
      <!--1、上架简历 2、下架简历 -->
      <div class="loginbtn" @click="loginbtn(1)" v-if="myResume.resume_status == 2">上架</div>
       <div class="loginbtn" @click="loginbtn1(2)" v-else>下架</div>
      <div style="height:1.5rem;"></div>

    </div>
    <div class="nomsg" v-else>
      <div v-if="showLoading == false">
        <img src="../../assets/noresume.png" alt="">
        <div>空空如也，点击<span @click="goCreate(0)" style="color:#FF4900">去创建</span> </div>
      </div>
    </div>
     <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
  </div>
</template>
<script>
import { Toast } from 'vant'

export default {
  data(){
    return{
      myResume:{},
      is_existence:'',
      showLoading:null
    }
  },
  mounted(){
    this.myResumedetail()
  },
  methods:{
    myResumedetail(){
        this.showLoading = true
        this.$http.post("/user/v1/personal_info/myResume",{reqType:"myResume"})
        .then((res)=>{
          let response = JSON.parse(res.data)
          console.log(response)
          if(response.code == 0){
              this.showLoading = false
              this.is_existence = response.data.is_existence
              response.data.perResume.address = response.data.perResume.province + response.data.perResume.city + response.data.perResume.district
              if(response.data.is_existence == 1){
                this.myResume = response.data.perResume
                response.data.perResume.educational_experience.map((value,index)=>{

                    value.start = value.start_time.replace(/-/g,".")
                    value.end = value.end_time.replace(/-/g,".")
                  
                    
                    return value.id = index
                })
                if(response.data.perResume.work_resume!=""){
                  response.data.perResume.work_resume.map((value,index)=>{

                    value.entry = value.entry_time.replace(/-/g,".")
                    value.quit = value.quit_time.replace(/-/g,".")

                    
                  return value.id = index
                })
                }
                
                sessionStorage.setItem("perResume",JSON.stringify(response.data.perResume))
              }
              
          }else{
            Toast.fail(response.msg)
          }
        })
        .catch((e)=>{console.log(e)})
    },
    loginbtn(type){
      
      //  console.log(type)
      this.$http.post("/user/v1/personal_info/resumeshelves",{reqType:"resumeshelves",type:type})
      .then((res)=>{
        res = JSON.parse(res.data)
        if(res.code == 0){
          Toast.success("保存成功")
          this.myResumedetail()
          // console.log(res)
        }else{
          Toast.fail(res.msg)
        }
        
      })
      .catch((e)=>{
        console.log(e)
      })
    },
    loginbtn1(type){
      
      //  console.log(type)
      this.$http.post("/user/v1/personal_info/resumeshelves",{reqType:"resumeshelves",type:type})
      .then((res)=>{
        res = JSON.parse(res.data)
        if(res.code == 0){
          Toast.success(res.msg)
          this.myResumedetail()
          // console.log(res)
        }else{
          Toast.fail(res.msg)
        }
        
      })
      .catch((e)=>{
        console.log(e)
      })
    },
    confirm(){
      console.log(1)
    },
    goCreate(type){
      // resumeType 0 -> 去创建, 1 ->去编辑 
      sessionStorage.setItem("resumeType",type)
      this.$router.push("./editInfo")
    },
    goback(){
      this.$router.push("./user")
    },
    
  },

}
</script>
<style scoped>
.item4{
  padding:0.2rem;
  border-bottom:0.01rem solid #ddd;
}
.i1{
  margin:0.2rem 0;
  font-size: 0.32rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #313131;
}
.i2{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #696969;
margin:0.2rem 0;
}
.i3{
  text-align: right;
  margin-right:0.2rem;
  
}
.huji{
  font-size: 0.30rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #222222;
margin-left:0.2rem;
margin-top:0.2rem;
}
.flex1{
  display:flex;
  align-items: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F6F6F;
}
.loginbtn{
	width: 6.86rem;
	height: 0.96rem;
	background: linear-gradient(90deg, #F55613 0%, #FC9E46 100%);
	border-radius: 0.48rem;
	line-height:  0.96rem;
	font-size: 0.36rem;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	margin:0 auto;
	margin-top:1rem;
  text-align: center;
}
.header{
  height:1rem;
  line-height: 1rem;
  border-bottom:0.01rem solid #ddd;
  font-size:0.3rem;
  text-align: center;
}
.nomsg1{
  text-align: center;
  margin:0.8rem 0;
}
.nomsg1 img{
   width:2rem;
  height:1.7rem;
}
.nomsg1 div{
   font-size: 0.32rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #B6B6B6;
}
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}

.nac > :first-child {
  margin-left: 0.32rem;
}
.nac {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  padding: 0.3rem 0;
  position: relative;
  border-bottom-color: #ebedf0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.edit{
  position:absolute;
  right:0.32rem;
  top:0.3rem;
  font-size: 0.3rem;
}
.nomsg{
  text-align: center;
  margin-top:2rem;
}
.nomsg img{
  width:3rem;
  height:2.7rem;
}
.nomsg div{
  font-size: 0.32rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #B6B6B6;
}
.list2{
  display:flex;
  flex-wrap: wrap;
  
}
.item2{
  
    height: 0.79rem;
    background: #FFFFFF;
    border-radius: 0.4rem;
    border: 0.02rem solid #ff4900;
    line-height:  0.79rem;
    padding:0 0.5rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff4900;
    margin-right:0.2rem;
    margin-top:0.2rem;
 
}
.items{
  margin-bottom: 0.62rem;
  border-bottom:0.01rem solid #ddd;
}
.post1{
   margin:0 0.41rem;
  margin-top:0.41rem;
  /* padding-bottom:0.36rem; */
}
.part{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #313131;
 margin-left:0.25rem;
 margin-top:0.3rem;
 padding-bottom:0.48rem;
 
}

.name{
  font-size: 0.32rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 600;
color: #313131;

}

.time{
font-size: 0.24;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #9D9D9D;
}
.gs{
  display:flex;
  margin-left:0.25rem;
  justify-content: space-between;
  align-items: center;
  margin-top:0.29rem;
}
.profession{
font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #313131;
margin-top:0.3rem;
 margin-left:0.25rem;
}
.money{
  font-size: 0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #FF4900;
}
.add{
 font-size:  0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #333333;
}
.postAddress{
  display:flex;
  justify-content: space-between;
  margin-top:0.3rem;
  margin-left:0.25rem;
}
.txt{
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  margin-left:0.17rem;
}
.post{
  margin:0 0.41rem;
  margin-top:0.41rem;
  padding-bottom:0.36rem;
  border-bottom:0.02rem solid #ddd;
}
.info1{
   margin:0 0.41rem;
   margin-top:0.39rem;
   border-bottom:0.02rem solid #ddd;
   padding-bottom:0.53rem;
}
.dot{
  width:0.09rem;
  height:0.09rem;
  background: #FF4900;
  border-radius: 50%;

}
.postTitle{
  display:flex;
  align-items: center;
  
}
.title{
  font-size: 0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #333333;
}
.item{
  display:flex;
  align-items:center;
  margin-right:0.5rem;
}
.img2{
  width:0.3rem;
  height:0.25rem;
   margin-right:0.1rem;
}
.img1{
  width:0.34rem;
  height:0.27rem;
  margin-right:0.1rem;
}
.text{
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
}
.imgstyle{
  width:0.3rem;
  height:0.29rem;
  margin-right:0.1rem;
}

.info{
  display:flex;
  justify-content: space-between;
  margin:0 0.41rem;
  margin-top:0.3rem;
  padding-bottom:0.3rem;
  border-bottom:0.02rem solid #ddd;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
.yuan {
  display: flex;
  align-items: center;
  margin-bottom: 0.28rem;
}
.uname {
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.48rem;
}
.zhi {
  margin-right: 0.16rem;
  margin-left: 0.3rem;
  background: #00beff;
  border-radius: 0.08rem;
  font-size: 0.18rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.05rem 0.1rem;
}
.seximg {
  width: 0.4rem;
  margin-left:0.2rem;
}
.userimg {
  width: 1.25rem;
  height:1.25rem;
  border-radius: 50%;
}
.gs1 {
  font-size: 0.24rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.3rem;
}

</style>